import React from 'react';

const Copyright = ({className}) => {
    return (
        <p className={className ? className : ''}>
            © {new Date().getFullYear()} Copyright By Marswell PTY LTD
        </p>
    );
};

export default Copyright;
