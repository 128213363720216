import React from 'react';
import Section from 'components/HOC/Section';

const IconBoxItemOne = ({data, border, padding, iconType, className, showBtn}) => {
    return (
        <Section id="aboutUs">
            <div
                className={`${className} icon-box mt--30 text-center ${border ? '' : 'no-border'} ${!border && padding ? 'with-padding' : ''}`}>
                <div className="inner">
                    <div className="content">
                        <h5 className="heading heading-h5">About Us</h5>
                        <p className="bk_pra">At Marswell Solar Panel, 
                            we're passionate about harnessing the power of the sun. 
                            With years of expertise in renewable energy solutions, 
                            our team of experts works tirelessly to design and 
                            deliver solar systems tailored to your unique needs. 
                            Whether you're looking to lower energy bills, reduce your carbon footprint, 
                            or take control of your energy future, 
                            we're here to make it happen. 
                            </p>
                    </div>
                </div>
            </div>
        </Section>
        
    );
};

export default IconBoxItemOne;