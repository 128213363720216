import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import Copyright from "components/footer/Copyright";

const FooterThree = () => {
    return (
        <footer id="page-footer-wrapper" className="page-footer-wrapper page-footer bg_color--4">
            <div className="copyright copyright--2 plr_sm--30">
                <Container className="ptb--40">
                    <Row className="align-items-center">
                        <Col xs={{span: 12, order: 'last'}} md={{span:6, order: 'first'}}>
                            <div className="copyright-left text-md-left text-center mt-4 mt-md-0">
                                <Copyright/>
                                <p>
                                    ABN: 86 626 043 034
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    );
};

export default FooterThree;
