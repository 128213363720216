import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import IconListItem from "components/icon-list/IconListItemOne";
import Section from 'components/HOC/Section';

const AboutPageArea2 = () => {
    return (
        <Section id="services">
        <div className="brook-feature-area ptb--150 ptb_md--80 ptb_sm--60 bg_color--1">
            <Container>
                <Row>
                    <Col>
                        <div className="brook-section-title mb--10">
                            <h3 className="heading heading-h3">Our Services</h3>
                        </div>

                        <Row>
                            {content.map(item => (
                                <Col md={6} className="mt--50" key={item.id}>
                                    <IconListItem data={item}/>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
        </Section>
    );
};

const content = [
    {
        id: 1,
        iconClass: "ion-android-people",
        title: "Energy Consultation and Custom Design",
        content: "Not sure where to start? Our experts provide personalized energy assessments and design a solar solution that fits your specific energy goals and budget."
    },
    {
        id: 2,
        iconClass: "ion-settings",
        title: "Solar Panel Installation",
        content: "Our certified technicians handle everything from system design to seamless installation. We ensure your solar system is optimized for performance and durability, leaving you worry-free."
    },
    {
        id: 3,
        iconClass: "ion-android-settings",
        title: "Maintenance and Repairs",
        content: "Keep your solar system in top condition with our expert maintenance and repair services. From routine checks to troubleshooting, we’ve got you covered."
    }
]

export default AboutPageArea2;
